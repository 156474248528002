import { Icon, mdBumps, WriskTheme } from '@wrisk/ui-components'

import logo from './assets/icons/logo.svg'
import bmwDriveaway from './assets/images/cropped-bmw-driveaway-hero.jpg'
import bmwFlex from './assets/images/cropped-bmw-flex-hero.jpg'
import BMW402021Background from './assets/images/promo/BMW402021/background.png'
import BMW402021Foreground from './assets/images/promo/BMW402021/foreground.png'
import I3122021Background from './assets/images/promo/I3122021/background.png'
import I3122021Foreground from './assets/images/promo/I3122021/foreground.png'
import fontFaces from './fonts'
import icons from './icons'
import illus from './illus'

const bmwColors = {
  white: '#FFFFFF',
  anthracite: '#262626',
  grey: ['#4D4D4D', '#666666', '#8E8E8E', '#BBBBBB', '#E6E6E6', '#F2F2F2'],
  blue: ['#0653B6', '#1C69D4', '#5BA1FA'],
}

const typoSizes = {
  xxl: {
    fontSize: ['32px', '32px', '40px', '48px'],
    lineHeight: ['40px', '40px', '48px', '56px'],
  },
  xl: {
    fontSize: ['24px', '24px', '28px', '32px'],
    lineHeight: ['32px', '32px', '36px', '40px'],
  },
  lg: {
    fontSize: ['20px', '20px', '24px'],
    lineHeight: ['28px', '28px', '32px'],
  },
  md: {
    fontSize: ['18px', '18px', '20px'],
    lineHeight: ['24px', '24px', '28px'],
  },
  base: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  xs: {
    fontSize: '12px',
    lineHeight: '16px',
  },
}

export const bmwTheme: WriskTheme = {
  // Common
  name: 'BMW',
  illus,
  fontFaces,
  icons,
  logo: {
    regular: logo,
  },
  radii: [0, 0, 0, 0],
  breakpoints: ['480px', '768px', '1024px', '1200px', '1600px'],
  space: [
    '0rem', //  0    0px
    '0.25rem', //  1    4px
    '0.5rem', //  2    8px
    '0.75rem', //  3   12px
    '1.0rem', //  4   16px
    '1.25rem', //  5   20px
    '1.5rem', //  6   24px
    '1.75rem', //  7   28px
    '2.0rem', //  8   32px
    '2.5rem', //  9   40px
    '3.0rem', // 10   48px
    '3.5rem', // 11   56px
    '4.0rem', // 12   64px
    '4.5rem', // 13   72px
    '5.0rem', // 14   80px
  ],
  fonts: {
    body: `'BMWTypeNext', sans-serif`,
    header: `'BMWTypeNext', sans-serif`,
    navbar: `'BMWTypeNext', sans-serif`,
    buttons: `'BMWTypeNext', sans-serif`,
    input: `'BMWTypeNext', sans-serif`,
    licencePlate: `'UKLicencePlate', sans-serif`,
  },
  lineHeights: ['1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em'],
  typoSizes,
  shadows: {
    default: '0 0 0 1px #e6e6e6',
    hover: '0 0 0 1px #e6e6e6',
    input: '0 0 0 2px #BBBBBB',
    inset: 'inset -8px 0 0 0 #fff, inset 8px 0 0 0 #fff',
    popupMenu: '0 4px 32px 0 rgba(0, 0, 0, 0.06)',
    radioNested: 'inset 0 0 0 1px #e6e6e6',
    radioStandalone: 'inset 0 0 0 1px #e6e6e6',
    radioHover: 'inset 0 0 0 1px #262626',
    radioChecked: 'inset 0 0 0 2px #262626',
  },
  navigation: {
    height: ['69px', '69px', '99px'],
  },
  transitions: {
    default: 'all 250ms ease',
  },
  colors: {
    chalk: bmwColors.white,
    background: bmwColors.white,
    body: bmwColors.anthracite,
    bodySecondary: '#262626b3',
    hyperlink: bmwColors.anthracite,
    focus: bmwColors.grey[3],
    hover: '#f4f5f680',

    primaryAccent: bmwColors.blue[1],
    secondaryAccent: bmwColors.grey[1],

    foregroundPromoBanner: bmwColors.white,
    backgroundPromoBanner: bmwColors.grey[0],

    foregroundPromoActivated: bmwColors.white,
    backgroundPromoActivated: '#3db014',

    textOnNavigation: '#262626b3',
    surfaceNavigation: bmwColors.white,

    textInfo: '#1C69D4',
    surfaceInfoSubdued: '#1C69D41A',
    surfaceInfo: bmwColors.blue[1],

    textCritical: '#d20000',
    textOnCritical: '#fff',
    surfaceCritical: '#d20000',
    surfaceCriticalHovered: '#9f0000',
    surfaceCriticalSubdued: '#f7e7e9',

    textWarning: bmwColors.anthracite,
    textOnWarning: bmwColors.anthracite,
    surfaceWarning: '#ffc766',
    surfaceWarningSubdued: '#ffc7661a',

    textHighlight: bmwColors.anthracite,
    textOnHighlight: bmwColors.anthracite,
    surfaceHighlight: bmwColors.grey[5],
    surfaceHighlightSubdued: '#0f172a1a',

    textSuccess: '#3db014',
    textOnSuccess: '#fff',
    surfaceSuccess: '#3db014',
    surfaceSuccessSubdued: '#3db0141a',

    border: bmwColors.grey[4],
    divider: bmwColors.grey[4],
    inactive: '#00000059',
    placeholder: '#00000059',

    textDisabled: bmwColors.grey[1],
    surfaceDisabled: bmwColors.grey[5],

    textFooter: bmwColors.white,
    dividerFooter: '#ffffff33',
    surfaceFooter: bmwColors.anthracite,

    radioCheckedBackground: '#262626',
    radioCheckedForeground: '#ffffff',

    dragBoxBorderActive: '#4F46E5',
    dragBoxBorderDefault: '#4B5563',

    progressBackgroundColor: '#9CA3AF',
    progressBarColor: '#4F46E5',
  },
  proposalImages: {
    'bmw-flex': bmwFlex as string,
    'bmw-driveaway': bmwDriveaway as string,
  },
  promoImages: {
    BMW402021: {
      background: BMW402021Background as string,
      foreground: BMW402021Foreground as string,
    },
    I3122021: {
      background: I3122021Background as string,
      foreground: I3122021Foreground as string,
    },
  },
  // Components
  Typo: {
    default: {},
    heading: {
      fontFamily: 'header',
    },
    buttons: {},
  },
  Heading: {
    default: {
      fontWeight: 'normal',
    },
    h1: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    h2: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    h3: {
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    h4: {
      fontWeight: 'normal',
    },
  },
  Logo: {
    default: {
      display: 'block',
      height: ['2.25rem', '2.25rem', '3.3125rem'],
      ml: [2, 2, 6],
    },
  },
  NavBar: {
    default: {
      px: [5, 5, 6],
      justifyContent: ['space-between', 'flex-start'],
      flexDirection: 'row-reverse',
      borderBottomColor: 'divider',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
  },
  NavAnchor: {
    default: {
      height: (theme: WriskTheme): (string | number)[] => theme.navigation.height,
      borderTopColor: 'transparent',
      borderTopStyle: 'solid',
      borderTopWidth: '4px',
      borderBottomColor: 'transparent',
      borderBottomStyle: 'solid',
      borderBottomWidth: '4px',
      [Icon]: {
        path: {
          fill: bmwColors.grey[2],
        },
        ellipse: {
          fill: bmwColors.grey[2],
        },
      },
      '&:hover': {
        bg: 'transparent',
        borderBottomColor: bmwColors.blue[0],
        [Icon]: {
          path: {
            fill: bmwColors.anthracite,
          },
          ellipse: {
            fill: bmwColors.anthracite,
          },
        },
      },
    },
    inverted: {
      height: (theme: WriskTheme) => theme.navigation.height,
      borderColor: 'rgba(255, 255, 255, 0.4)',
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: [0, 0, 1],
      display: 'flex',
      alignItems: 'center',
      px: 4,
      color: bmwColors.white,
      [Icon]: {
        path: {
          fill: bmwColors.white,
        },
        ellipse: {
          fill: bmwColors.white,
        },
      },
      '&:hover': {
        bg: 'transparent',
        borderBottomColor: bmwColors.blue[0],
        borderBottomStyle: 'solid',
        borderBottomWidth: '4px',
        borderTopColor: 'transparent',
        borderTopStyle: 'solid',
        borderTopWidth: ['4px', '4px', '3px'],
        color: bmwColors.white,
        [Icon]: {
          path: {
            fill: bmwColors.white,
          },
          ellipse: {
            fill: bmwColors.white,
          },
        },
      },
    },
  },
  AppName: {
    default: {
      order: 1,
      pl: [0, 0, 6],
      height: (theme: WriskTheme) => theme.navigation.height,
      ...typoSizes.base,
      color: 'bodySecondary',
      fontWeight: 'bold',
    },
  },
  TextArea: {
    default: {
      p: mdBumps,
      borderWidth: 1,
      borderColor: 'divider',
      borderStyle: 'solid',
      boxShadow: 'none',
      '&:focus': {
        boxShadow: 'input',
        borderColor: 'transparent',
      },
    },
  },
  TextInput: {
    default: {
      p: mdBumps,
      borderWidth: 1,
      borderColor: 'divider',
      borderStyle: 'solid',
      boxShadow: 'none',
      '&:focus': {
        boxShadow: 'input',
        borderColor: 'transparent',
      },
    },
    ghost: {
      boxShadow: 'none',
      py: mdBumps,
      px: 0,
    },
    licencePlate: {
      fontFamily: 'licencePlate',
      boxShadow: 'none',
      py: mdBumps,
      px: 0,
    },
  },
  InputWrapper: {
    default: {
      px: mdBumps,
      borderWidth: 1,
      borderColor: 'divider',
      borderStyle: 'solid',
      boxShadow: 'none',
      '&:focus-within': {
        boxShadow: 'input',
        borderColor: 'transparent',
      },
    },
    ghost: {
      px: 0,
      boxShadow: 'none',
    },
  },
  PrimaryButton: {
    variants: {
      default: {
        backgroundColor: bmwColors.blue[1],
        color: 'chalk',
        '&:hover:not([disabled])': {
          backgroundColor: bmwColors.blue[0],
        },
      },
      inverted: {
        backgroundColor: bmwColors.blue[1],
        color: 'chalk',
        '&:hover:not([disabled])': {
          backgroundColor: bmwColors.blue[0],
        },
      },
      critical: {
        bg: 'surfaceCritical',
        color: 'textOnCritical',
        '&:hover:not([disabled])': {
          backgroundColor: 'surfaceCriticalHovered',
        },
      },
    },
  },
  SecondaryButton: {
    variants: {
      default: {
        backgroundColor: 'secondaryAccent',
        color: 'chalk',
        '&:hover:not([disabled])': {
          backgroundColor: bmwColors.grey[0],
        },
      },
      inverted: {
        backgroundColor: 'chalk',
        color: 'secondaryAccent',
        '&:hover:not([disabled])': {
          backgroundColor: 'chalk',
        },
      },
    },
  },
}
